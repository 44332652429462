@import "../../../styles/base/abstracts";

.hbs-tooltip-highlight {
  display: inline-block;
  padding-right: $spacing-xxs;
  color: $c-spot;
  cursor: help;
}

.hbs-tooltip {
  position: relative;
  display: inline-block;

  &--hover {
    @include mq("max", $bp-desktop) {
      display: none;
    }
  }

  &--popover {
    @include mq($bp-desktop) {
      display: none;
    }
  }
}

.hbs-tooltip__trigger-wrapper {
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: help;
}

.hbs-tooltip__trigger {
  @include size(1.7em);
  font-size: 10px;
  align-items: center;
  background-color: $c-spot;
  border-radius: 50%;
  color: $c-bg;
  cursor: help;
  display: flex;
  justify-content: center;

  &:focus-visible {
    outline: 4px solid var(--c-spot);
    outline-offset: 3px;
  }
}

.hbs-tooltip__trigger-icon {
  @include size(1em);
  transform: translate(-0.03em, 0.03em);
}

.hbs-tooltip__content {
  @include padding($spacing-sm);
  @include margin(x $spacing-sm);
  @include small-text;
  background-color: $c-bg-complementary;
  box-shadow: $box-shadow;
  color: $c-text;
  max-width: 300px;
  display: flex;
  z-index: $z-index-1;

  @include mq-reduced-motion {
    animation: showUp $t-time-sm $t-ease-out forwards;
    opacity: 0;
  }

  li + li {
    margin-top: $spacing-xs;
  }
}

.hbs-tooltip__close {
  @include size($icon-size-xs);
  align-items: center;
  color: $c-text-light;
  display: flex;
  justify-content: center;
  margin-left: $spacing-xs;
  min-width: 11px;
  order: 2;
}

.hbs-tooltip__title {
  @include border(
    $dir: bottom,
    $color: $c-text,
    $padding: 2,
    $start: 0,
    $end: 0
  );
  padding-bottom: $spacing-xxs;
  margin-bottom: $spacing-xs;
  font-weight: $fw-semibold;
  font-size: $fs-xs;
  position: relative;
}
